<template>
  <AcquisitionManageMoleculeStyled>
    <div class="organization">
      {{ $t('selects.organization') }}:
      <Multiselect
        class="multiselect"
        trackBy="id"
        label="name"
        placeholder="Type to search"
        :options="organizations"
        :searchable="true"
        :multiple="false"
        :loading="isLoading"
        :internal-search="false"
        :clear-on-select="false"
        :close-on-select="false"
        :options-limit="300"
        :limit="1"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="true"
        :closeOnSelect="true"
        :value="selectedOrganization"
        :disabled="disabledOrganizationSelector"
        @search-change="$emit('update:organizationsSearchQuery', $event)"
        @select="$emit('update:selectOrganization', $event)"
      />
    </div>
    <div class="product">
      {{ $t('selects.product') }}:
      <Multiselect
        class="multiselect"
        trackBy="id"
        label="name"
        placeholder="Type to search"
        :options="productsAvailable"
        :searchable="true"
        :multiple="false"
        :loading="isLoading"
        :internal-search="false"
        :clear-on-select="false"
        :close-on-select="false"
        :options-limit="300"
        :limit="1"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="true"
        :closeOnSelect="true"
        :value="selectedProduct"
        :disabled="!selectedOrganization"
        @search-change="$emit('update:productsSearchQuery', $event)"
        @select="$emit('update:selectProduct', $event)"
      />
    </div>
    <div class="tier">
      {{ $t('selects.productTier') }}:
      <Multiselect
        class="multiselect"
        trackBy="id"
        label="name"
        placeholder="Type to search"
        :options="productsTierAvailable"
        :searchable="true"
        :multiple="false"
        :loading="isLoading"
        :internal-search="false"
        :clear-on-select="false"
        :close-on-select="false"
        :options-limit="300"
        :limit="1"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="true"
        :closeOnSelect="true"
        :value="selectedProductTier"
        :disabled="!selectedOrganization || !selectedProduct"
        @select="$emit('update:selectProductTier', $event)"
      />
      <div v-if="productsTierNotAvailable">{{ $t('messages.productsTierNotAvailable') }}:</div>
    </div>
    <div class="type">
      {{ $t('selects.acquisition') }}:
      <Multiselect
        class="multiselect"
        placeholder="Type to search"
        :options="acquisitionsTypes"
        :searchable="false"
        :multiple="false"
        :loading="isLoading"
        :internal-search="false"
        :clear-on-select="false"
        :close-on-select="false"
        :options-limit="300"
        :limit="1"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="true"
        :closeOnSelect="true"
        :value="selectedAcquisitionType"
        :disabled="!selectedOrganization || !selectedProduct || !selectedProductTier"
        @select="$emit('update:selectAcquisitionType', $event)"
      />
    </div>
  </AcquisitionManageMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import Multiselect from 'vue-multiselect'

const AcquisitionManageMoleculeStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1rem;
  grid-template-areas:
    'organization product '
    'tier type';

  .organization {
    grid-area: 'organization';
  }
  .product {
    grid-area: 'product';
  }
  .tier {
    grid-area: 'tier';
  }
  .type {
    grid-area: 'type';
  }
  .multiselect {
    margin-top: 1rem;
  }
`

export default {
  components: {
    AcquisitionManageMoleculeStyled,
    Multiselect,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    selectedOrganization: {
      type: Object,
      default: null,
    },
    disabledOrganizationSelector: {
      type: Boolean,
      default: false,
    },
    selectedProduct: {
      type: Object,
      default: null,
    },
    selectedProductTier: {
      type: Object,
      default: null,
    },
    selectedAcquisitionType: {
      type: String,
      default: null,
    },
    organizations: {
      type: Array,
      default: () => [],
    },
    productsAvailable: {
      type: Array,
      default: () => [],
    },
    productsTierAvailable: {
      type: Array,
      default: () => [],
    },
    acquisitionsTypes: {
      type: Array,
      default: () => [],
    },
    productsTierNotAvailable: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
