<template>
  <ButtonStyled :disabled="disabled" @click="$emit('click')">
    <template v-if="!isloading">{{ label }}</template>
    <LoadingDots v-else />
  </ButtonStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { buttonReset, flexCenter } from '@styles/mixins'
import { LoadingDots } from '@common/components'

const ButtonStyled = styled('button')`
  ${props => buttonReset(props)}
  ${flexCenter}
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  background: ${props => props.theme.colors.orange};
  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primaryLighter};
  }
  &:disabled {
    color: ${props => chroma(props.theme.colors.muted).brighten(1).css()};
    background: ${props => chroma(props.theme.colors.muted).darken(1).css()};
  }
`

export default {
  components: {
    ButtonStyled,
    LoadingDots,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isloading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
