var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AcquisitionCreateStyled', [_c('header', [_vm._v(_vm._s(_vm.$t('titles.createAcquisition')))]), _c('AcquisitionManageMolecule', {
    attrs: {
      "isLoading": false,
      "selectedOrganization": _vm.selectedOrganization,
      "disabledOrganizationSelector": false,
      "selectedProduct": _vm.selectedProduct,
      "selectedProductTier": _vm.selectedProductTier,
      "selectedAcquisitionType": _vm.selectedAcquisitionType,
      "organizations": _vm.organizations,
      "productsAvailable": _vm.productsAvailable,
      "productsTierAvailable": _vm.productsTierAvailable,
      "acquisitionsTypes": _vm.acquisitionsTypes,
      "productsTierNotAvailable": _vm.productsTierNotAvailable
    },
    on: {
      "update:selectOrganization": function updateSelectOrganization($event) {
        _vm.selectedOrganizationId = $event.id;
      },
      "update:selectProduct": function updateSelectProduct($event) {
        _vm.selectedProductId = $event.id;
      },
      "update:selectProductTier": function updateSelectProductTier($event) {
        _vm.selectedProductTierId = $event.id;
      },
      "update:selectAcquisitionType": function updateSelectAcquisitionType($event) {
        _vm.selectedAcquisitionType = $event;
      },
      "update:organizationsSearchQuery": function updateOrganizationsSearchQuery($event) {
        _vm.organizationsSearchQuery = $event;
      },
      "update:productsSearchQuery": function updateProductsSearchQuery($event) {
        _vm.productsSearchQuery = $event;
      }
    }
  }), _c('div', {
    staticClass: "action"
  }, [_c('Button', {
    attrs: {
      "label": _vm.$t('actions.assignProduct'),
      "disabled": !_vm.selectedOrganization || !_vm.selectedProduct || !_vm.selectedProductTier
    },
    on: {
      "click": _vm.confirmCreateAcquisition
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }