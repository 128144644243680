<template>
  <AcquisitionCreateStyled>
    <header>{{ $t('titles.createAcquisition') }}</header>
    <AcquisitionManageMolecule
      :isLoading="false"
      :selectedOrganization="selectedOrganization"
      :disabledOrganizationSelector="false"
      :selectedProduct="selectedProduct"
      :selectedProductTier="selectedProductTier"
      :selectedAcquisitionType="selectedAcquisitionType"
      :organizations="organizations"
      :productsAvailable="productsAvailable"
      :productsTierAvailable="productsTierAvailable"
      :acquisitionsTypes="acquisitionsTypes"
      :productsTierNotAvailable="productsTierNotAvailable"
      @update:selectOrganization="selectedOrganizationId = $event.id"
      @update:selectProduct="selectedProductId = $event.id"
      @update:selectProductTier="selectedProductTierId = $event.id"
      @update:selectAcquisitionType="selectedAcquisitionType = $event"
      @update:organizationsSearchQuery="organizationsSearchQuery = $event"
      @update:productsSearchQuery="productsSearchQuery = $event"
    />
    <div class="action">
      <Button
        :label="$t('actions.assignProduct')"
        @click="confirmCreateAcquisition"
        :disabled="!selectedOrganization || !selectedProduct || !selectedProductTier"
      />
    </div>
  </AcquisitionCreateStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
// import { debounce } from 'vue-debounce'
import { DateTime } from 'luxon'

import { FlashMessages } from '@common/singletons'

import AcquisitionManageMolecule from './Molecules/AcquisitionManageMolecule'
import Button from '@/components/Atomic/Atoms/ButtonAtom.vue'

import LIST_ORGANIZATIONS_QUERY from '#/graphql/acquisition/listOrganizationsWithAcquisition.gql'
import LIST_PRODUCTS_QUERY from '#/graphql/acquisition/listProductsWithTierLevel.gql'
import ACQUISITIONS_TYPES from '#/graphql/acquisition/listAcquisitionStatusEnum.gql'
import CREATE_ONE_ACQUISITION from '#/graphql/acquisition/createOneAcquisition.gql'

const AcquisitionCreateStyled = styled('div')`
  margin: 2rem;
  padding: 1rem;
  background: ${p => chroma(p.theme.colors.white).alpha(0.1).css()};
  backdrop-filter: blur(7px);
  border-radius: 0.5rem;

  header {
    margin-bottom: 1.5rem;
  }
  .action {
    margin: 1rem 0;
    display: flex;
    justify-content: flex-end;
  }
`

export default {
  components: {
    AcquisitionCreateStyled,
    AcquisitionManageMolecule,
    Button,
  },
  data() {
    return {
      organizations: [],
      products: [],
      acquisitionsTypes: [],
      selectedOrganizationId: null,
      selectedProductId: null,
      selectedProductTierId: null,
      selectedAcquisitionType: 'PURCHASED',
      organizationsSearchQuery: '',
      productsSearchQuery: '',
    }
  },
  computed: {
    selectedOrganization() {
      return this.organizations.find(o => o.id === this.selectedOrganizationId)
    },
    selectedProduct() {
      return this.products.find(p => p.id === this.selectedProductId)
    },
    selectedProductTier() {
      return this.productsTierAvailable.find(t => t.id === this.selectedProductTierId)
    },
    productsAvailable() {
      return this.products.map(p => ({
        ...p,
        name: `${p.name} (${p.type})`,
      }))
    },
    tierLevelsAllreadyAcquiredByOrganization() {
      return this.selectedOrganization.acquisitions.map(a => a?.productTierLevel?.id)
    },
    tierLevelsOfSelectedProductFromOrganization() {
      return this.selectedOrganization.acquisitions
        .filter(a => {
          return a?.productTierLevel?.product?.id === this.selectedProduct.id
        })
        .map(t => t?.productTierLevel?.tierLevel)
    },
    productsTierAvailable() {
      if (!this.selectedProduct) {
        return []
      } else {
        return this.selectedProduct.tierLevels
          .filter(t => {
            return (
              !this.tierLevelsAllreadyAcquiredByOrganization.includes(t.id) &&
              this.tierLevelsOfSelectedProductFromOrganization.every(tl => t.tierLevel > tl)
            )
          })
          .map(t => ({
            ...t,
            name: `Tier ${t.tierLevel}`,
          }))
      }
    },
    productsTierNotAvailable() {
      return this.selectedProduct && this.productsTierAvailable.length === 0
    },
  },
  methods: {
    async debouncedfindOrganizations(search) {
      this.searchQuery = search
    },
    selectOrganization(event) {
      this.selectedOrganization = event
    },
    selectProduct(event) {
      this.selectedProduct = event
    },
    selectProductTier(event) {
      this.selectedProductTier = event
    },
    selectAcquisitionType(event) {
      this.selectedAcquisitionType = event
    },
    confirmCreateAcquisition() {
      this.$root.$emit(
        'activateOverlay',
        'ConfirmOverlay',
        {
          confirmText: `
            Are you sure you want to assign 
            <b> ${this.selectedProductTier?.name} </b> of product 
            <b> ${this.selectedProduct?.name} </b> to  
            <b>${this.selectedOrganization?.name} </b> in <b>${this.selectedAcquisitionType}</b> state
          `,
          onConfirm: this.createAcquisition,
          onConfirmArgs: {},
        },
        this.onCloseSettings,
      )
    },
    async createAcquisition() {
      try {
        const res = await this.$apollo.mutate({
          mutation: CREATE_ONE_ACQUISITION,
          variables: {
            data: {
              product: {
                connect: {
                  id: this.selectedProduct.id,
                },
              },
              productTierLevel: {
                connect: {
                  id: this.selectedProductTierId,
                },
              },
              status: this.selectedAcquisitionType,
              organizationId: this.selectedOrganizationId,
              orderedAt: this.selectedAcquisitionType === 'ORDERED' ? DateTime.now() : null,
              trialStartedAt: this.selectedAcquisitionType === 'TRIAL_STARTED' ? DateTime.now() : null,
              purchasedAt: this.selectedAcquisitionType === 'PURCHASED' ? DateTime.now() : null,
            },
          },
          update: async (store, { data }) => {
            const acquisition = data.createOneAcquisition
            const { organizations } = store.readQuery({
              query: LIST_ORGANIZATIONS_QUERY,
            })
            const organizationToUpdate = organizations.find(s => s.id === this.selectedOrganization.id)
            organizationToUpdate.acquisitions.push(acquisition)
            store.writeQuery({
              query: LIST_ORGANIZATIONS_QUERY,
              data: {
                organizations,
              },
            })
          },
        })
        this.selectedOrganizationId = null
        this.selectedProductId = null
        this.selectedProductTierId = null
        this.$root.$emit('closeOverlay')
        this.$emit('reload', res.id)
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
  },
  apollo: {
    organizations: {
      query: LIST_ORGANIZATIONS_QUERY,
      debounce: 150,
      variables() {
        if (this.organizationsSearchQuery !== '') {
          return {
            where: {
              OR: [
                {
                  name_contains: this.organizationsSearchQuery,
                },
                {
                  alias_contains: this.organizationsSearchQuery,
                },
              ],
            },
          }
        }
        return undefined
      },
    },
    products: {
      query: LIST_PRODUCTS_QUERY,
      debounce: 150,
      variables() {
        if (this.productsSearchQuery !== '') {
          return {
            where: {
              name: {
                contains: this.productsSearchQuery,
                mode: 'insensitive',
              },
            },
          }
        }
        return undefined
      },
    },
    acquisitionsTypes: {
      query: ACQUISITIONS_TYPES,
      update({ acquisitionsTypes }) {
        return acquisitionsTypes?.enumValues.map(t => t.name)
      },
    },
  },
  // created() {
  //   this.findOrganizations = debounce(search => {
  //     this.debouncedfindOrganizations(search)
  //   }, 150)
  // },
  // mounted() {
  //   this.findOrganizations('')
  // },
}
</script>
