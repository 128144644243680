var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ButtonStyled', {
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [!_vm.isloading ? [_vm._v(_vm._s(_vm.label))] : _c('LoadingDots')], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }